.disclaimer-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .disclaimer-title {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .disclaimer-text {
    font-size: 1em;
    line-height: 1.6;
    color: #555;
    padding: 10px;
  }
  
  .disclaimer-section {
    margin-bottom: 15px;
  }
  
  .disclaimer-section::before {
    content: '•';
    color: #333;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
  