.home-header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: Arial, Helvetica, sans-serif;
  
}

.navbarContainer{
  background: #2c5437;
}
.home-desktop-menu {
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.home-links {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.home-nav12 {
  font-size: 1.3rem;
  color: #fff;
  transition: 0.3s;
  margin-right: 180px;
}
.home-nav2 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
}
.home-nav3 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
}
.home-nav4 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
}
.home-nav5 {
  transition: 0.3s;
  margin-left: var(--dl-space-space-twounits);
}
.home-buttons {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-login {
  margin-right: 32px;
}

.profileIcon{
  height: 30px;
  width: 30px;
  opacity: 0.9;
  margin-right: 20px;
  /* color: rgb(37, 37, 37); */
  color: #fff;
}

.log-out-btn {
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  border: 1px solid rgb(59, 59, 59)
}


.home-icon {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}

.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-top {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-close-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  cursor: pointer;
  height: var(--dl-size-size-xsmall);
}
.home-links1 {
  flex: 0 0 auto;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-nav1 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav21 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav31 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav41 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-nav51 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-buttons1 {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
@media(max-width: 767px) {
  .home-navbar-interactive {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-desktop-menu {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-nav1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav21 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav31 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav41 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-nav51 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
}  

@media(max-width: 479px) {
.home-navbar-interactive {
  padding: var(--dl-space-space-unit);
}
.home-mobile-menu {
  padding: 16px;
}
}

