.accordion{
    width: 95%;
    background: none !important;
    --bs-accordion-bg: none !important; 
    --bs-accordion-color: #fff !important;
    --bs-accordion-border-color: none !important;
    --bs-accordion-btn-color: #fff !important;
    font-family: Arial, Helvetica, sans-serif !important;
    border-radius: 0px !important;
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important; 
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;


}

.accordion-button {
    background: none !important;
    font-size: 2.7rem  !important;
    padding-bottom: 40px !important;
    padding-top: 40px !important;
    font-weight: 400 !important;


  }

  .accordion-button:focus {
    box-shadow: none !important;
  }

  .accordion-button:not(.collapsed){
    color:#fff !important;
    border-bottom: none !important;
  }
  
  .accordion-button::after{
    color: #fff;
  }

  .accordion-button:not(.collapsed) {
    color: none !important;
  }

.accordion .accordion-item 
{
    color: white;
    border-bottom: 1px #fff solid !important;
}
.accordion-body{
    /* border-bottom: 1px #fff solid !important; */
}