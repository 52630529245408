.bot-messages {
  display: flex;
  
  position: relative;
  text-align: center;
  /* height: 88vh; */
  background-color: rgb(252, 252, 252);
  /* overflow: hidden; */
}

.content {
  width: 100vw;
  margin-top: 0px;
  border-top: 1px solid #e5e5e5;
  margin-left: 70px;
}
.bot-messages .content .header {
  display: flex;
  align-items: flex-start; 
  justify-content: space-between;
}

.bot-messages .content h2 {
  flex-grow: 1; /* Allow the h2 to grow and take up available space */
  text-align: center; /* Center align the text within the h2 */
}

button {
  font-family: Arial, Helvetica, sans-serif !important;
  margin-left: auto; /* Push the button to the far right */
}

table {
  margin: 1rem;
  border-radius: 2rem;
  border: 1rem hidden #443C68;
  border-collapse: collapse;
  box-shadow: 0 0 0 1px black;
  overflow: hidden;
  /* width: 10vw; */
  /* min-width: 9rem; */
}

li, p, a, td{
  font-size: 1rem;
  /* line-height: 1.6; */
}

thead {
  /* font-size: 1.8rem; */
  background-color: #443C68;
  color: #fff;
}

th,
td {
  border: 1px solid #443C68;
  min-width: 1rem;
  padding: 1.5rem;
  /* line-height: 1.7; */
}

td {
  /* font-size: 1.4rem; */
}


.filters {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: space-evenly; */
  /* background-color: #f8f9fa; */
  padding: 20px;
  /* border-radius: 8px; */
  margin-bottom: 20px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.filters .filter-options{
  display: block;

}



/* Label styling */
.filters label {
  text-align: start;
  /* display: block; */
  /* margin-bottom: 10px; */
  /* font-size: 1.1em; */
  color: #555;
  margin-right: 50px;
}

/* Input styling */
.filters input[type="text"] {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;

}


/* Select styling */
.filters .react-select__control {
  margin-top: 5px;
  margin-bottom: 15px;
  font-size: 1em;
  min-width: 328px;


}

.filters .react-select__control--is-focused {
  border-color: none;
  /* box-shadow: 0 0 0 1px #007bff; */
}

.filters .react-select__multi-value {
  /* background-color: #007bff; */
  color: black;
  /* width: 200px; */
}

.filters .react-select__multi-value__label {
  color: black;


}

.filters .react-select__multi-value__remove {
  color: black;
}

.filters .react-select__multi-value__remove:hover {
  /* background-color: #0056b3; */
  color: white;
}