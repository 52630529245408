.homeBody {
    height: 100vh;
}


.homeBody .homeBody-container .mission{
    padding: 10px;
    background: #233D2B;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5%;

    
}

.homeBody .homeBody-container .mission .mission-content{
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: center; */
}


.homeBody .homeBody-container .mission .mission-content h1 {
    display: flex;
    align-items: center;
    color: #F4F1EB;
    font-weight: 600;
    /* font-size: 1.2rem; */
}

.homeBody .homeBody-container .mission .mission-content span {
    font-size: 60px;
    margin-left: 10px;
}
.homeBody .homeBody-container .mission .mission-content p {
    font-size: 1.2rem;
    color: #fff;
    font-weight: 400;
    max-width: 90%;
    margin-top: 10px;
    /* font-size: 1.2rem; */
}

.homeBody .homeBody-container .mission .mission-accordion {
    margin-top: 50px;
    width: 100%;
    
}

.homeBody .homeBody-container .team {
    padding: 10px;
    background: #3D5430;
    max-height: 80vh;
    min-height: 70vh;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    /* padding-top: 5%; */
    padding-left: 5%;
    padding-right: 5%;
}

.homeBody .homeBody-container .team .team-content{
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    
}

.homeBody .homeBody-container .team .team-content h1{
    display: flex;
    align-items: center;
    color: #F4F1EB;
    font-weight: 600;
}

.homeBody .homeBody-container .team .team-content p {
    font-size: 1.2rem;
    color: #fff;
    font-weight: 400;
    max-width: 90%;
    margin-top: 10px;
    /* font-size: 1.2rem; */
}

.hiring {
    padding: 10px;
    background: #587761;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hiring-content {
    max-width: 90%;
    margin: 0 auto;
    min-height: 90vh;
    margin-top: 70px;
}

.hiring-image-container {
    position: relative;
    width: 100%;
}

.hiring-image-container img {
    width: 100%;
    height: 60vh;
    border-radius: 20px;
    opacity: 0.6;
}

.text {
    position: absolute;
    top: 55%; 
    left: 15%; 
    transform: translate(-50%, -50%);
    color: white; 
    font-size: 3.5rem; 
    font-weight: bold; 
    text-align: left;
    opacity: 1;
}

.hiring-content-body {
    margin-top: 50px;
    background: #C7D2CA;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    
}

.hiring-content-body p {
    font-size: 1.2rem;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
}

.contact-us{
    padding: 10px;
    background: #33443C;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 5%;
}

.contact-us .contact-content{
    display: flex;
    justify-content: space-around;
    align-items: top;
    width: 100%;
    height: 25vh;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
}

.contact-us .contact-content p {
    font-size: 1.3rem;
    /* margin-bottom: 20px; */
}

.contact-us .contact-content h1{
    font-size: 4.5rem;
}

.contact-us .contact-content h2{
    margin-top: 60px;
    text-align: left;
}




  

  
  


  
@media only screen and (max-width: 768px) {
   
    .homeBody {
        height: 100%;
    }
    .homeBody .homeBody-container .mission
    {
        padding-top: 15%;
    }
    .text {
        font-size: 2rem;
        left: 30%;
    }
    .hiring-image-container img {
        
        height: 30vh;
        
    }
    .contact-us .contact-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-height: 30vh; /* Change height to auto for responsiveness */
        height: auto;
    }

    .contact-us .contact-content h1 {
        font-size: 1.5rem; /* Adjust font size for smaller screens */
        width: 100%;
    }
    .contact-us .contact-content p {
        font-size: 1rem;
        width: 80%;
    }

    .contact-us .contact-content h2 {
        margin-top: 0;
        font-size: 1.2rem; /* Adjust font size for smaller screens */
        width: 80%;
    }

    .contact-info {
        display: flex;
        flex-direction: column;
        justify-content: right;
        align-items:center;
       
    }

    .contact-info p span
    {
        display: block;
    }
    
    
  
  }
  


  