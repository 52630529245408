footer{
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

footer p{
  margin-left: 20px;
  margin-top: 10px;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 24px;
}

.footer-all-links
{
  display: flex;

  align-items: center;
}
.footer-link {
  margin-right: 10px;
  font-size: 24px;
  color: #0077b5; 
  text-decoration: none;
}

.footer-link i {
  padding-bottom: 5px;
  font-size: 30px;
  vertical-align: middle;
}

@media only screen and (max-width: 768px) {
  footer{
    display: flex;
    flex-direction: row;
    height: auto;
}

footer p {
    font-size: 1rem;
}

}
