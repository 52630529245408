/* AnimatedLogo.module.css */
@import url('https://fonts.googleapis.com/css?family=Montserrat:300');

@import url('https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap');


:root {
  --black: #333;
}

ol,
ul,
dl {
  margin-top: 0 !important;
  margin-bottom: 0.5rem !important;
  padding-left: 0 !important;
}

.logo {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title {
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: Arial, Helvetica, sans-serif;
   */
   font-family: "Finger Paint", sans-serif;
  font-size: 1.5rem;
  letter-spacing: 0.15rem;
  position: relative;
  opacity: 0;
  font-weight: 600;
  animation-duration: 2s;
  animation-delay: 2.1s;
  animation-fill-mode: forwards;
  color: #fff;
  margin-top: 5px;
  /* margin-bottom: 0.5rem; */
}

.title.animate {
  animation-name: reveal;
  animation-delay: 0.7s;
}

ul {
  margin: 0.15rem auto auto 0.3rem;
  list-style: none;
  height: 1.5rem;
  display: flex;
  flex-flow: column;
  padding: 0;
}

.tile {
  margin: auto;
  width: 1.2vw;
  height: 10%;
  background-color: #fff;
  opacity: 0;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.tile.animate {
  animation-name: reveal;
}

.tile:nth-of-type(1) {
  /* left: 40%; */
  animation-delay: 1.4s;
}

.tile:nth-of-type(2) {
  /* left: 20%; */
  animation-delay: 0.7s;
}

.tile:nth-of-type(3) {
  animation-delay: 0;
}

@keyframes reveal {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }

  50% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}


@media only screen and (max-width: 768px) {
  .tile{
    width: 5vw;
  }
}