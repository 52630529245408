@import url('https://fonts.googleapis.com/css2?family=Finger+Paint&display=swap');



.hero-banner{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-banner .banner-content{
  padding: 10px  ;
}
.hero-banner .banner-content p{
  margin: 10px  ;
}


.hero-banner .banner-content .banner-btn{
  font-size: 0.9rem;
  color: black;
  background: #fff;
  cursor: pointer;
  text-decoration: underline;
  opacity: 0.7;
  
}
.home-hero {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  /* height: 100vh; */
  background: #2c5437 ;
  /* background: #2B5439; */
}
.home-hero .hero-container {
  
  width: 100%;
  margin: 0 3%;
  display: flex;
  flex-direction: column;
  align-items: space-around;
  justify-content: flex-start;
 
}



.home-hero .hero-container .navbar {
  /* margin: 0 3%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 5px; */
}

.home-hero .hero-container .navbar .logo{
  padding-left: 20px;
}


.home-hero .hero-container .navbar .nav-items {
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
.home-hero .hero-container .navbar .nav-items ul{
  display: flex;
  flex-direction: row;
}

.home-hero .hero-container .navbar .nav-items li {
  display: inline-block;
  margin-right: 35px;
  font-family: Arial, Helvetica, sans-serif;
}

.home-hero .hero-container .navbar .nav-items li:last-child {
  margin-right: 0;
}

.home-hero .hero-container .navbar .nav-items li a {
  color: #fff;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: lighter;
  
}

.home-hero .hero-container .navbar .login-btn button {
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  background: none;
  border: none;
  padding: 5px 15px;
  font-size: 1.3rem;
  cursor: pointer;
  border: 1px solid rgb(59, 59, 59) ;
  border-radius: 10px;
  font-weight: 400;

}

.home-hero .hero-container .navbar .profileIcon{
  height: 35px;
  width: 35px;
  opacity: 0.9;
  margin-top: 5px;
  margin-right: 10px;
  color: white;
}



.home-hero .hero-container img {
  margin-top: 3%;
  max-width: 45%;
  max-height: auto;
}

.profile-placeholder {
  height: 35px;
  width: 55px;
  visibility: hidden;
}

.home-hero .hero-container .hero-body  p {
  font-family: "Finger Paint", sans-serif;
  /* color: rgb(185, 181, 181); */
  color: #fff;
  font-weight: 400;
  font-style: normal;
  font-size: 2.3rem;
  margin-top: 15px;
}

.home-hero .hero-container span {
  text-decoration: underline;
  color: #fff;
}

.hamburger-menu {
  display: none;
}

.menu {
  display: none;
  background-color: transparent;
  width: 100%;

}

.menu .open {
  display: none;
}

.logo-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
/* .logo-top{
  width: 100%;
} */
.logo-name{
  margin: 0 !important;
  padding: 0 !important;
  font-size: 6.5rem !important;
  margin-top: -20px !important;
}

.logo-bottom {
  margin: 0 !important;
  padding: 0 !important;
  margin-top: -2px !important;
}
.logo-top, .logo-bottom{
  min-width: 600px;
}

.banner-and-video {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 120px; 
  padding: 5%;
  padding-top:2% ;
}


.qr-image-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.banner-and-video img  {
  margin-top: 0% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  
  height: 250px;
  min-width: 250px;
  border-radius: 8px; 
}
.qr-image-container p{
  color: #fff;
  margin-top: 10px;
  max-width: 300px;
  font-size: 14px;
  word-wrap: wrap;
}
.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner-and-video iframe {
  margin-top: 20px;
  width: 400px;
  height: 250px; 
  border: none;
  border-radius: 8px; 
  align-self: stretch;

}



.video-container p {
  color: #fff;
  margin-top: 10px;
  max-width: 400px;
  font-size: 14px;
  margin-bottom: 0px;

}
@media only screen and (max-width: 848px) {
  .banner-and-video {
    /* flex-direction: column; */
    gap: 30px;
    padding: 5%;
  }

  .banner-and-video img{
   
    height: 130px;
    min-width: 130px;

  }
  .banner-and-video iframe {
    /* align-self: center; */
    margin-top: 0px;
    height: 130px;
    width: 170px;
   
    
  }

  .video-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 10px;
  }
 
  .qr-image-container p {
    text-align: center;
    font-size: 10px !important;
    min-width: 150px;
    max-width: 150px;
  }

  .video-container p {
    text-align: center;
    font-size: 10px !important;
    max-width: 150px;
    /* margin: 0px !important; */
  }
}



@media only screen and (max-width: 768px) {
  .hero-banner .banner-content p {
      font-size: 0.8rem;
  }
  .hero-banner .banner-content .banner-btn {
    font-size: 0.8rem;
}
 
 
  .home-hero .hero-container {
    text-align: center;
  }

  .home-hero .hero-container p {
    font-size: 1.8rem;
    margin: 5%;
	
  }

  .home-hero .hero-container .navbar {
    
    margin-top: 5px;
  }
  .home-hero .hero-container .navbar .logo{
    padding-top: 10px;
    padding-left: 0px;
  }

  .home-hero .hero-container .navbar ul li {
    margin-right:1px;
  }

  .home-hero .hero-container .navbar li a {
    font-size: 1.3rem;
    display: none;
  }
  .home-hero .hero-container .navbar button {
    display: none;
  }

  .hamburger-menu {
    display: block;
    cursor: pointer;
    
  }

  .menu {
    position: absolute;
    /* display: block; */
    background:  rgb(44, 84, 55);
    
    width: 55%;
    text-align: center;
    margin-top: 25%;
    height: 30%;
    margin-left: 40%;
    height: 80vh;
    
  }

  .menu.open {
    display: block;
  
  }

 
  

  .menu ul {
    display: flex;
    justify-content: right;
   
    list-style-type: none;
    padding: 0;
    margin: 0;
    /* margin-left: 50%; */
    height: 100%;
    z-index: 101;
    /* background: red;     */
  }

  .menu ul li {
    font-family: Arial, Helvetica, sans-serif;
    /* background-color: rgb(48, 100, 63); */
    margin-bottom: 30px;

    
  }
  .menu ul button {
   
    text-align: center;
    color: #fff;
    border: none;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    font-weight: lighter;
    
    margin:0;
    padding: 0;
    margin-bottom: 25px;
    margin-left: 60%;
  }

  .menu ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: lighter;
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0
  }

  .cross-button {
    font-size: 1.2rem;
    font-weight: 600;
    color: #fff;
  }

/*   .home-hero .hero-container img {
    margin-top: 20%;
    max-width: 80%;
    max-height: auto;
  } */

.logo-name{
    margin: 0 !important;
    padding: 0 !important;
    font-size: 5rem !important;
    margin-top: -20px !important;
  }

  .logo-top{
    padding-top: 30px;
  }

  .logo-top, .logo-bottom{
    min-width: 450px ;
  }

}


@media only screen and (max-width: 468px) {
  .home-hero .hero-container .navbar ul{
    display: none;
    max-width: 10px ;
  }

	.logo-name{
    margin: 0 !important;
    padding: 0 !important;
    font-size: 3rem !important;
    margin-top: -10px !important;
  }

  .logo-top, .logo-bottom{
    min-width: 275px ;
  }

  .logo-top{
    padding-top: 30px;
  }



}