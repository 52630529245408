.data-analytics {
    display: flex;
    
    position: relative;
    text-align: center;
    /* height: 88vh; */
    background-color: rgb(252, 252, 252);
    
    /* overflow: hidden; */
  }

  .data-analytics .content {
    width: 100vw;
    margin-top: 0px;
    border-top: 1px solid #e5e5e5;
    margin-left: 70px;
    padding-top: 5px;
}

.data-container{
    margin-top: 50px;
    
}

.data-container .message-count{
    text-align: center;
}

/* .registration-count{
    display: flex;
    justify-content: space-between;

} */


.data-analytics {
    display: flex;
  }
  
  .content {
    flex: 1;
    padding: 20px;
  }
  
  .data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
/*     width: 100vw; */
  }
  
  .message-count,
  .daily-active-user,
  .daily-message-count,
  .registration-count-container {
    width: 100%;
    margin-bottom: 20px;
  }

  .registration-count-container{
    margin-top: 100px;
  }

  .pie-container{
    display: flex;
    height: 500px;
  }

  .total-pie-count{
    display: flex;
    
    align-items: center;
    justify-content: space-around;
  }

  .registration-details{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 5%;
    text-align: start;
    margin-left: 5%;

  }
  .registration-details li {
    margin-bottom: 10px;
    font-size: 16px;
    gap:1
  }

  .registration-details ul {
    list-style-type: none;
    padding: 0;
  }
  
  .registration-details li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .field-name {
    margin-right: 20px; /* Adjust this value as needed */
  }
  
  .field-value {
    text-align: right;
  }
 

  
  /* .registration-count-container {
    margin-top: 100px;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-height: 400px;
    width: 100%;
  }
   */
  /* .registration-count {
    flex: 1;
  }
   */
  /* .registration-details {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
   */
  /* .registration-details h4 {
    margin-bottom: 20px;
  }
  
  
  
  
   */
