@import url('https://fonts.googleapis.com/css2?family=Arvo&display=swap');

.App {

  font-family: "Arial";
 
 
  
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main-content{
  /* background-color: red; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 768px)  {
  /* .main-content{
    padding-top: 65px  ;
  } */
}
