.magic-chat-pro-container {
    display: flex;
    
    position: relative;
    
    height: 88vh;
    background-color: rgb(252, 252, 252);
    overflow: hidden;
}

.content {
    width: 100vw;
    margin-top: 0px;
    border-top: 1px solid #e5e5e5;
}