.app-container {
  display: flex;
  height: 90vh;
 
  /* background-color: red; */
}

.app-container .left-section {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  padding-bottom: 40px;
  border-right: 1px solid #e5e5e5;
  max-width: 900px;
}

.left-section .system-message {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.2rem;
}

.left-section textarea {
  height: 150px;
  font-size: 1rem;
  width: 95%;
  padding: 5px;
  border-radius: 5px;
  /* color: #e5e5e5; */
  color: black;
  font-weight: 300;
}

.left-section input {
  height: 45px;
  font-size: 1rem;
  max-width: 100%;
  border: none;
}
.left-section .file {
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* align-items: center; */
}

.left-section .file p {
  font-size: 1rem;
  margin: 0;
  margin-left: 10px;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}

.left-section .file label {
  font-size: 1rem;

  width: fit-content;
}
.left-section .file-input {
  display: flex;
}

.left-section .file-input-container {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 10px;
  margin-top: 20px;
}

.upload-button {
  font-size: 1rem;
}

.left-section .file-list {
  margin-top: 10px;
}

.left-section .file-item {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  align-items: center;
  /* background-color: #f0f0f0; */
  padding: 5px;
  margin-bottom: 5px;
}

.left-section .remove-file-icon {
  cursor: pointer;
  color: black;
  margin-left: 5px;
}

.right-section {
  width: 100%;
  padding: 40px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  /* min-width: 850px; */
}

.chat-window {
  display: flex;
  flex-direction: column;
  /* if i commment out flex property then it will take full length of chat window to show chat  */
  font-size: 1.1rem;
  height: 70vh;

  overflow-y: auto;
}
.right-section input {
  height: 50px;
  font-size: 1rem;
  width: 100%;
}

.user,
.bot {
  margin: 10px;
  padding: 10px;
  border-radius: 5px;
}

.user {
  background-color: #c2bcb924;
  align-self: flex-end;
  max-width: 700px;
  text-align: left;
}

.bot {
  background-color: #fffdfd;
  align-self: flex-start;
  max-width: 700px;
  text-align: right;
}

.message-box {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
}

.message-box input {
  flex: 1;
  margin-right: 10px;
  font-weight: 300;
}

button {
  cursor: pointer;
}

.message-box button {
  font-size: 1.2rem;
  margin-right: 5px;
  background: none;
}
.magic-email-container .left-section .buttonFlat {
  font-size: 1rem;
  margin-top: 70px;
}

.buttonFlat:disabled {
  cursor: not-allowed;
}

@media only screen and (max-width: 768px) {
  .app-container {
    flex-direction: column;
  }

  .left-section {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #e5e5e5;
  }

  .right-section {
    padding: 20px;
  }
}

