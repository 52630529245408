.chat-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 90vh;
    /* padding: 100px; */
}
  
.chat-container .right-section {
    /* width: 100%; */
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /* min-width: 850px; */
    max-width: 1024px;
  }
  
  
  .chat-container .chat-window {
    display: flex;
    flex-direction: column;
    /* if i commment out flex property then it will take full length of chat window to show chat  */
    font-size: 1.1rem;
    height: 70vh;
    text-align: left;
    overflow-y: auto;
  }

  .chat-container.user,
  .chat-container .bot {
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .chat-container .user {
    background-color: #c2bcb924;
    align-self: flex-end;
  }
  
  .chat-container .bot {
    background-color: #fffdfd;
  text-align: left !important; 
  align-self: flex-start;
  margin: 10px;
  padding: 10px;
  height: auto;

  }

  .chat-container .bot p {
    margin: 0; /* Reset the margin for <p> tags within .bot */
  }
  /* .right-section input {
    height: 50px;
    font-size: 1rem;
    width: 100%;
  } */
  
  
  
  .chat-container .message-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    width: 100%;
    margin-top: 10px;
    /* position: relative; */
  }
  
  
  .chat-container .message-box input {
    flex: 1;
    margin-right: 10px;
  }
  
  
  .chat-container .message-row {
    display: flex;
    width: 100%;
    height: 100%;
    
  }

  .chat-container .message-box button {
    font-size: 1.2rem;
    margin-right: 5px;
    background: none;
  }

  .chat-container .input-container{
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    margin-right: 10px;
  }
  .chat-container .input-container label{
    position: absolute;
    right: 0%;
    width: 10%;
    top: 20%;
  }
  .chat-container .message-box input[type="file"] {
    position: absolute;
    opacity: 0;
    display: none;
    /* width: 10%;
    height: 70%;
    right: 16%;
    top: 17%; */
    cursor: pointer;
  }
  
  .chat-container button {
    cursor: pointer;
  }
  
